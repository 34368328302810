import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { Tabs } from "react-tabs";

export const ReactTabs = styled(Tabs)`
  > nav {
    text-align: center;
    margin-bottom: 15px;
  }
  .react-tabs__tab-list {
    background-color: ${themeGet("colors.lightGrey")};
    padding: 6px;
    display: inline-flex;
    border-radius: 70px;
    justify-content: center;
    @media only screen and (max-width: 1280px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 480px) {
      display: flex;
      padding: 6px;
      max-width: 100%;
      justify-content: flex-start;
    }
  }
  .react-tabs__tab {
    color: ${themeGet("colors.headingColor")};
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    padding: 14px 18px;
    transition: all 0.3s ease-in-out 0s;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
      padding: 7px 7px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 14px;
      + .react-tabs__tab {
        margin-left: 12px;
      }
    }
  }
  .react-tabs__tab--selected {
    background-color: ${themeGet("colors.white")};
    border-radius: 70px;
    box-shadow: 0px 10px 30px ${themeGet("colors.greyLowOpacity")};
  }
  .react-tabs__tab-panel {
    figure {
      margin: 0;
      position: relative;
      animation-duration: 400ms;
      img {
        @media (min-width: 1280px) and (max-width: 1140px) {
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      ::before,
      ::after {
        content: "";
        position: absolute;
        z-index: -1;
      }
      ::before {
        width: 223px;
        height: 202px;
        left: 0;
        top: -5px;
        @media (min-width: 1025px) and (max-width: 1440px) {
          left: 80px;
          top: -25px;
        }
        @media (max-width: 1024px) {
          margin-top: -15px;
        }
        @media (max-width: 768px) {
          width: 150px;
          height: 150px;
          background-size: contain;
          top: -10px;
        }
        @media (max-width: 480px) {
          width: 100px;
          height: 100px;
          background-size: contain;
          top: -10px;
        }
      }
      ::after {
        height: 223px;
        width: 202px;
        right: -30px;
        bottom: -35px;
        @media (min-width: 1025px) and (max-width: 1440px) {
          right: 95px;
          bottom: -55px;
        }
        @media (max-width: 768px) {
          width: 150px;
          height: 150px;
          background-size: contain;
          bottom: -10px;
        }
        @media (max-width: 480px) {
          width: 100px;
          height: 100px;
          background-size: contain;
          bottom: -10px;
        }
      }
    }
  }
`;

export const DesignsWrapper = styled.div`
  padding-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  .masonryGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`;

export const DesignItem = styled.div`
  width: calc(100% / 3);
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  @media only screen and (max-width: 991px) {
    width: calc(100% / 2);
    margin-bottom: 12px;
  }
  @media only screen and (max-width: 624px) {
    width: calc(100% / 2);
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 12px;
  }
`;

export const DesignItemInner = styled.div`
  position: relative;
  background-color: ${themeGet("colors.white")};
  border-radius: 10px 10px 0 0;
  padding: 0px 0px;
  box-shadow: ${themeGet("colors.blueShadowColor")} 0px 15px 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${themeGet("colors.blueShadowColor")} 0px 20px 60px,
                ${themeGet("colors.visuellaPrimary")} 0px 0px 0px 3px;
  }
  .line {
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid ${themeGet("colors.lineColor")};
  }
  h3 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 16px;
  }
  p {
    color: ${themeGet("colors.textColor")};
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    > span {
      color: ${themeGet("colors.visuellaPrimary")};
      font-weight: 500;
    }
  }
`;
